html {
  scroll-behavior: smooth;
}
.fade-in {
  animation: fadeIn ease-in 1;
  visibility: visible !important;
}

.fade-out {
  animation: fadeOut ease-out 1;
  visibility: hidden !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.hover-effect {
  transition: all 0.3s ease-in-out;
}

.hover-effect:hover {
  transform: scale(1.1);
}
.button {
  margin-top: 10px;
}
.contact-us {
  height: 60vh;
  margin: 50px auto;
}
@media (max-width: 768px) {
  .contact-us {
    height: 90vh;
    margin: 50px auto;
  }
  .form {
    margin-top: 10px;
  }
}
li a {
  text-decoration: none;
  color: white;
  font-size: 20px;
}
li a:hover {
  color: rgb(238, 235, 62);
  text-decoration: underline;
}
p a {
  text-decoration: none;
  color: white;
  /* font-size: 20px; */
}
.myfooter {
  height: 100vh;
}
@media only screen and (max-width: 600px) {
  .myfooter {
    height: 70%;
    /* display: flex; */
  }
  iframe {
    height: 300;
  }
}
#map {
  height: 400px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}
