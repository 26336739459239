/* Why Choose Us */

.why-choose-us {
  padding: 100px 0;
  background-color: #faf8f8;
  height: 80vh;
}
@media (max-width: 600px) {
  .why-choose-us {
    height: 100vh;
  }
  .description {
    font-size: 14px;
  }
}

.why-choose-us .why-choose-us-header {
  margin-top: 100px;
  margin-bottom: 100px;
}

.why-choose-us .why-choose-us-header h2 {
  font-weight: 700;
  text-align: center;
  color: #000;
  margin-bottom: 30px;
}

.why-choose-us .why-choose-us-header p {
  font-size: 18px;
  text-align: center;
  color: #333;
}

.why-choose-us .why-choose-us-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.why-choose-us .why-choose-us-list-item {
  width: calc(33.33% - 20px);
  background-color: #fff;
  padding: 30px;
  text-align: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.why-choose-us .why-choose-us-list-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

.why-choose-us .why-choose-us-list-item .why-choose-us-list-item-icon {
  font-size: 50px;
  margin-bottom: 20px;
  color: #007bff;
}

.why-choose-us .why-choose-us-list-item h3 {
  font-weight: 700;
  font-size: 22px;
  color: #000;
  margin-bottom: 20px;
}

.why-choose-us .why-choose-us-list-item p {
  font-size: 16px;
  color: #555;
  margin-bottom: 0;
}
