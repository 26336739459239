.fade-in {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.fade-in.loaded {
  opacity: 1;
}
@media only screen and (max-width: 600px) {
  .custom-row {
    margin-bottom: 30px;
  }
  .size {
    width: 150px;
  }
  .custom-column {
    margin: auto 0;
    /* background-color: lightgray; */
    /* padding: 5px; */
    display: flex;
    /* flex-direction: column; */
    /* border: solid red 2px; */
    /* width: 100%; */
    /* height: 100%; */
    /* justify-content: center; */
    /* align-items: center; */
    /* flex-direction: column; */
  }
}

.myhover:hover {
  font-weight: 700;
  color: red;
}
