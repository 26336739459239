/* .our-services {
  position: relative;
  padding: 100px 0;
  padding-top: 100px;
  width: 100%;
  height: 100vh;
} */

.service-icon {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 5px;
  padding-top: 10px;
}

.service-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

.service-description {
  font-size: 2rem;
  color: #fff;
  line-height: 1.5;
  text-align: justify;
}

.flip-box {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 20px auto;
  /* border: solid red 3px; */
  perspective: 1000px;
  /* border-radius: 20px; */
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 90%;
  /* border-radius: 20px; */
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 20px;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #f2f2f2;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-box-back {
  background-color: #333;
  color: #f2f2f2;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flip-box-back p {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
}
@media only screen and (max-width: 600px) {
  .servicecontainer {
    margin-top: 50px;
  }
  .flip-box {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 5px auto;
    /* border: solid red 3px; */
    perspective: 1000px;
    /* border-radius: 20px; */
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    /* border-radius: 20px; */
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    backface-visibility: hidden;
  }

  .flip-box-front {
    background-color: #f2f2f2;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .flip-box-back {
    background-color: #333;
    color: #f2f2f2;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .flip-box-back p {
    padding: 5px;
    text-align: center;
    font-size: 10px;
    line-height: 1.5;
  }
  .service-icon {
    font-size: 0.5rem;
    color: #fff;
    margin-bottom: 5px;
    padding-top: 5px;
  }

  .service-title {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
  }

  .service-description {
    font-size: 1rem;
    color: #fff;
    line-height: 1.5;
    text-align: justify;
  }
}
.networking,
.web,
.pwa,
.autocad,
.printing,
.graphic {
  background-size: cover;
  background-position: center;
}
.networking {
  background-image: url("../images/computer_networks.jpg");
}
.web {
  background-image: url("../images/webdev.jpg");
}
.pwa {
  background-image: url("../images/pwa.png");
}
.graphic {
  background-image: url("../images/graphic.jpg");
}
.printing {
  background-image: url("../images/printing.PNG");
}
.autocad {
  background-image: url("../images/autocad.jpg");
}

.flip-box-active .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-active .flip-box-back {
  transform: rotateY(0deg);
}

.flip-box-active .flip-box-front {
  transform: rotateY(180deg);
}
