.navbar-custom {
  background-color: #ffcccc;
}

.navbar-custom .navbar-nav {
  margin: 0 auto;
}
.custom-link:hover {
  background-color: white !important;
  color: red !important;
}
.linktext {
  margin-left: 10px;
  /* padding-left: 10px; */
}
.linktext:hover {
  color: black;
  font-weight: 800;
}
.socialicon {
  margin-left: 15px;
  font-size: 20px;
}
.socialicon:hover {
  color: blue;
  font-weight: 500;
}
